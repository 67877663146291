
export const useRunStatusCheck = async(checks, baseId, getData) => {
    let result = { hidden: true, code: '', to: '', color: 'info' }
    let data = []
    for( let check of checks ) {
        for( let need of check.needs ) {
            if( !data[need] )
                data[need] = await getData(need, baseId, data)
        }

        try {
            if( !check.passCheck(data) ){
                result = check
                break
            }
        } catch(e) {
            result.id = baseId
            return result
        }
    }
    
    result.id = baseId
    return result
}